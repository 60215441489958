@media screen and (min-width: 768px) and (max-width: 1024px) {
  .change_flex {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 320px) and (max-width: 1076px) {
  .wrapper {
    width: 100%;
    flex-direction: unset;
  }
}

@media screen and (max-width: 320px) {
  .wrapper {
    width: 351px;
    flex-direction: unset;
  }
}
