/* SignUPPage.css */

.signUpPage-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  font-family: Arial, sans-serif;
  padding: 20px;
  padding-top: 30px;
}

.signUpPage-flex_row {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 50%;
}

.left-content {
  flex: 1;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.signUp-logo {
  width: 150px;
  margin-bottom: 20px;
}

.signUp-form {
  display: flex;
  flex-direction: column;
}

.signUpPage-title {
  font-size: 40px;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 50px;
  color: #2b2147;
  font-weight: 700;
}

.signUpPage-details {
  margin: 20px 0;
  color: #888;
}

.form-group {
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}

.submit-btn {
  display: flex;
  align-items: center;
  color: #2b2146;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 0;
  background-color: #fff;
  transition: background-color 0.3s ease;
  border: 2px solid #000;
  justify-content: center;
}
.googleImg {
  width: 29px;
  height: 29px;
  margin-right: 7px;
}

.checkbox {
  margin-top: 10px;
  text-align: center;
}
.signIn {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

/* Customize the label (the container) */
.container {
  margin-top: 7px;
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 17px;
  width: 23px;
  background-color: #fff;
  border: 1px solid #3527d6;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fbfeff;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #3527d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.right-content {
  height: 100vh;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #3527d6;
  border-radius: 15px;
  margin-bottom: 10px;
}

.right-content-inner {
  text-align: center;
}

.right-content-title1 {
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #fbfeff;
}

.schoolRecords {
  background-color: #d8eebd;
  border-radius: 10px;
  color: #3527d6;
  padding: 1px 10px;
}
.onePlace {
  border: 2px solid #bef47c;
  border-radius: 70%;
  padding: 2px 15px;
}
.right-content-title2 {
  font-size: 48px;
  font-weight: 700;
  color: #fbfeff;
}

.school-records-img {
  width: 70%;
  border-radius: 15px;
}
@media (max-width: 1200px) {
  .left-content {
    padding: 20px 50px;
  }
  .right-content-title1 {
    font-size: 32px;
  }

  .right-content-title2 {
    font-size: 32px;
  }
}
@media (max-width: 800px) {
  .right-content-title1 {
    font-size: 28px;
  }

  .right-content-title2 {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .signUpPage-flex_row {
    flex-direction: column;
    width: 100%;
    padding: 20px 100px;
  }
  .left-content {
    padding: 0;
    padding-top: 40px;
  }
  .right-content {
    display: none;
  }
}
@media (max-width: 575px) {
  .signUpPage-flex_row {
    padding: 20px 50px;
  }
}
@media (max-width: 480px) {
  .signUpPage-flex_row {
    padding: 20px 0;
  }
}
