@media screen and (max-width: 1056px) {
  .width_full {
    width: 100%;
  }
}

@media screen and (max-width: 830px) {
  .change_display {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .width_full {
    width: -webkit-fill-available;
  }
}
