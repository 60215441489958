@media screen and (max-width: 320px) {
  .small-width {
    width: 351px;
  }
}

.navbar {
  display: flex;
}
.small_screen {
  display: none;
}

@media screen and (max-width: 767px) {
  .navbar {
    display: none;
  }
  .small-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .small_screen {
    display: block;
  }
  .auth {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1278px) {
  .show_navbar {
    display: flex;
  }
  .showbig_navbar{
    display: none
  }
}

@media screen and (min-width: 1279px) {
  .show_navbar {
    display: none;
  }
  .showbig_navbar{
    display:flex
  }
}
