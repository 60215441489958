.chat_dialog {
  position: absolute;
  bottom: 22px;
  left: 0;
  right: 0;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .chat_dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
