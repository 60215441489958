@media screen and (min-width: 500px) and (max-width: 1076px) {
  .change_resolution {
    flex-wrap: wrap;
  }
  .change_width {
    width: 100%;
  }
  .change_flex {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .change_direction {
    justify-content: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
  .change_resolution {
    flex-wrap: wrap;
  }
  .change_width {
    width: 100%;
  }
  .change_flex {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .change_direction {
    justify-content: center;
    width: 330px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}
