/* TeachersList.css */

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #3527d6 #eee;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3527d6;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #3527d6;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #eee;
}

@media screen and (max-width: 830px) {
  .change_width {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .custom-scrollbar {
    width: 500px;
    height: 400px;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .custom-scrollbar div {
    width: 24.5%;
    /* background-color: #eee; */
    float: none;
    height: 90%;
    margin: 0 0.25%;
    display: inline-block;
    zoom: 1;
  }

  .change_width {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .width_full {
    width: -webkit-fill-available;
  }
}
