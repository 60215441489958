@media screen and (min-width: 768px) and (max-width: 1075px) {
  .change_width {
    width: 450px;
  }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
  .change_width {
    width: 320px;
  }
}

@media screen and (max-width: 1077px){
  .max_width{
    width: 100%
  }
}
