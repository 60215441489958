@media screen and (min-width: 320px) and (max-width: 375px) {
  .small-footer {
    width: 356px;
  }
}

@media screen and (max-width: 420px) {
  .works {
    display: flex;
    flex-direction: column;
  }
}
